import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes: [
    {
      path: '/about',
      name: 'About',
      component: function () {
        return import('../views/About.vue')
      }
    },
    {
      path: '/privacy_policy',
      name: 'PrivacyPolicy',
      component: function () {
        return import('../views/jurists/PrivacyPolicy.vue')
      }
    },
    {
      path: '/terms_of_use',
      name: 'TermsUse',
      component: function () {
        return import('../views/jurists/TermsOfUse.vue')
      }
    },
    {
      path: '/cookies',
      name: 'Cookies',
      component: function () {
        return import('../views/jurists/Cookies.vue')
      }
    },
    {
      path: '/payment_policy',
      name: 'Payment_policy',
      component: function () {
        return import('../views/jurists/PaymentPolicy.vue')
      }
    },
    {
      path: '/return_policy',
      name: 'Return_policy',
      component: function () {
        return import('../views/jurists/ReturnPolicy.vue')
      }
    },
    {
      path: '/complaint_policy',
      name: 'Complaint_policy',
      component: function () {
        return import('../views/jurists/ComplaintPolicy.vue')
      }
    },
    {
      path: '/faq',
      name: 'Faq',
      component: function () {
        return import('../views/Faq.vue')
      }
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: function () {
        return import('../views/Pricing.vue')
      }
    },
    {
      path: '/relate',
      name: 'Relate',
      component: function () {
        return import('../views/Relate.vue')
      }
    },
    {
      path: '/service/:name_service',
      name: 'Service',
      component: function () {
        return import('../views/Service.vue')
      }
    },
    {
      path: '/services',
      name: 'Services',
      component: function () {
        return import('../views/Services.vue')
      }
    },
    {
      path: '/ask',
      name: 'Ask',
      component: function () {
        return import('../views/Ask.vue')
      }
    },
    {
      path: '/cpanel',
      name: 'Cpanel',
      component: function () {
        return import('../views/cpanel/Dashboard.vue')
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: function () {
        return "kuku"
      }
    },
    {
      path: '/confirmRegistration',
      name: 'confirmRegistration',
      component: function () {
        return import('../views/confirmRegistration.vue')
      }
    },
    {
      path: '*',
      name: "other",
      component: Home
    }
  ]
})

router.beforeResolve((to, from,  next) => {
  // If this isn't an initial page load.
  //if (to.name) {
  // Start the route progress bar.
  //    NProgress.start();
  //    document.getElementById("preloader").style.display = "block";
  //}
  //console.log(to.name);
  next()
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  //NProgress.done();
  //document.getElementById("preloader").style.display = "none";
  //console.log("afterEach");
  window.scrollTo(0, 0, {
    top: 1000,
    behavior: 'smooth'
  });
  $(".nav-menu").removeClass("active");
});


export default router
