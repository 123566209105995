<template>
  <ul class="list-marker">
    <slot>
    </slot>
  </ul>
</template>

<script>
export default {
  name: "ListMarker"
}
</script>

<style lang=scss>
@import '../assets/scss/base/variables';

.list-marker {
  li {
    position: relative;
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      top: .35em;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $primary;
    }
  }

  p {
    margin: 0;
  }

  * + & {
    margin-top: 25px;
  }

  * + * {
    margin-top: 15px;
  }
}
</style>
