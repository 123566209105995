<template>
    <section class="section" id="services">
        <div class="container">
            <h2 class="text-center">{{ $t("services") }}</h2>
            <div class="features-flex">
                <div class="features-text-block left wow fadeInLeft">
                    <h3 class="feature-title-large">
                        {{ $t("direct_forwarding_of_messages") }}
                    </h3>
                    <p>{{ $t("direct_forwarding_of_messages_line_1") }}</p>
                    <p>{{ $t("direct_forwarding_of_messages_line_2") }}</p>
                    <p>{{ $t("direct_forwarding_of_messages_line_3") }}</p>
                </div>
                <div class="features-img-block wow fadeInRight">
                    <img src="@/assets/img/slider/wa_tg.png" alt="" />
                </div>
            </div>
            <div class="features-flex">
                <div class="features-img-block wow fadeInLeft">
                    <img src="@/assets/img/slider/ElzaBot.png" alt="" />
                </div>
                <div class="features-text-block right wow fadeInRight">
                    <h2 class="feature-title-large">
                        {{ $t("collecting_news_in_one_channel") }}
                    </h2>
                    <p>{{ $t("collecting_news_in_one_channel_line_1") }}</p>
                    <p>{{ $t("collecting_news_in_one_channel_line_2") }}</p>
                    <p>{{ $t("collecting_news_in_one_channel_line_3") }}</p>
                </div>
            </div>
            <div class="features-flex">
                <div class="features-text-block left wow fadeInLeft">
                    <h2 class="feature-title-large">
                        {{ $t("forwarding_messages_to_the_right_recipient") }}
                    </h2>
                    <p>
                        {{
                            $t(
                                "forwarding_messages_to_the_right_recipient_line_1",
                            )
                        }}
                    </p>
                    <p>
                        {{
                            $t(
                                "forwarding_messages_to_the_right_recipient_line_2",
                            )
                        }}
                    </p>
                    <p>
                        {{
                            $t(
                                "forwarding_messages_to_the_right_recipient_line_3",
                            )
                        }}
                    </p>
                </div>
                <div class="features-img-block wow fadeInRight">
                    <img src="@/assets/img/slider/ElzaBot2.png" alt="" />
                </div>
            </div>
            <div class="features-flex">
                <div class="features-img-block wow fadeInLeft">
                    <img src="@/assets/img/slider/message2.png" alt="" />
                </div>
                <div class="features-text-block right wow fadeInRight">
                    <h2 class="feature-title-large">
                        {{ $t("easy_work_with_bots") }}
                    </h2>
                    <p>{{ $t("easy_work_with_bots_line_1") }}</p>
                    <p>{{ $t("easy_work_with_bots_line_2") }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style lang="scss">
@import "../assets/scss/base/variables";

.features-grid {
    display: grid;
    margin-top: 48px;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
}

.feature-block {
    padding: 48px 32px 56px;
    border-radius: 8px;
    background-color: $gray-800;
}

.feature-block.no-bg {
    background-color: transparent;
}

.feature-block.no-bg.no-padding {
    padding: 0;
}

.feature-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
}

.feature-title {
    margin-bottom: 10px;
    font-family: "Space Grotesk", sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
}

.features-flex {
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;
}

.feature-title-large {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 38px;
    line-height: 54px;
}

.features-text-block {
    z-index: 2;
    width: 50%;
    margin-bottom: 0;
    flex-direction: column;
}

.features-text-block.left {
    padding-right: 8.333%;
}

.features-text-block.right {
    padding-left: 8.333%;
}

.features-img-block {
    z-index: 0;
    width: 50%;
}

@media screen and (max-width: 991px) {
    .features-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
    }
    .features-flex {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .feature-title-large {
        font-size: 38px;
        line-height: 1.3;
    }
    .features-text-block {
        width: 100%;
        margin-bottom: 64px;
        padding-right: 0;
        justify-content: center;
    }
    .features-img-block {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .features-flex {
        padding-top: 48px;
        padding-bottom: 48px;
        flex-direction: column;
    }
    .features-text-block.left {
        padding-right: 0;
    }
    .features-text-block.right {
        padding-left: 0;
        order: -1;
    }
    .feature-tabs {
        border-left: 6px solid $gray-700;
        border-top-style: none;
    }
    .feature-tabs.w--current {
        border-left-color: $primary;
    }
    .feature-nav-tabs-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 479px) {
    .features-grid {
        grid-column-gap: 0;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto auto;
    }
    .features-flex {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .feature-title-large {
        font-size: 28px;
    }
    .features-text-block {
        margin-bottom: 40px;
    }
}
</style>
