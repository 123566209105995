import 'normalize.css'
import 'lightgallery/dist/css/lightgallery.min.css'
import './assets/fonts/fonts.scss'
import './assets/scss/style.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
//import BackToTop from 'vue-backtotop'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n';
import axios from 'axios'
import config from '@/store/config'
import VueCookie from 'vue-cookie';

window.$ = require('jquery');
window.JQuery = require('jquery');
window.jQuery = require('jquery');

import $ from 'jquery'
import WOW from 'wow.js/dist/wow'

let wow = new WOW(
  {
    boxClass:     'wow',      // animated element css class (default is wow)
    animateClass: 'animated', // animation css class (default is animated)
    offset:       0,          // distance to the element when triggering the animation (default is 0)
    mobile:       true,       // trigger animations on mobile devices (default is true)
    live:         true,       // act on asynchronously loaded content (default is true)
    callback:     function(box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null // optional scroll container selector, otherwise use window
  }
);
wow.init();

require('lightgallery/dist/js/lightgallery-all.min')

Vue.config.productionTip = process.env.NODE_ENV == "production" ? false : true;
Vue.config.devtools = process.env.NODE_ENV == "production" ? false : true;
Vue.config.productionTip = process.env.NODE_ENV == "production" ? false : true;

Vue.use(BootstrapVue)
Vue.use(VueI18n);
Vue.use(VueCookie);


if ( Vue.cookie.get('elza_lng') == undefined ) {
    Vue.cookie.set('elza_lng', 'en');
}

const i18n = new VueI18n({
    locale: "en",
    silentTranslationWarn: true,
    messages: {}
});

const loadedLanguages = []

function setI18nLanguage (lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) setI18nLanguage(lang)
        return Promise.resolve()
    }
    $("#app").hide();
    $("#preloader").show();

    return axios({url: `${config.getters.mainUrl}/service/translate`,method: 'post'})
      .then(response => {
        let items = response.data.data;
        loadedLanguages.push(lang);
        i18n.setLocaleMessage("en", items.en);
        i18n.setLocaleMessage("ua", items.ua);
        i18n.setLocaleMessage("pl", items.pl);

        setTimeout(() => {
						$("#preloader").hide();
            $("#app").show();
        }, 300)
    })
}

new Vue({
    router,
    store,
    render: function (h) { return h(App) },
    i18n,
    created() {
        loadLanguageAsync("en")
        store.commit('lang', "en");
    }
}).$mount('#app');