<template>
  <header class="header">
    <div class="navbar container">
      <div class="brand-wrapper">
        <router-link to="/" class="brand">
          <img src="@/assets/img/elza.png" alt="" class="logo"/>
          <h5>ElzaBot</h5>
        </router-link>
      </div>
      <div class="local-wrap">
        <div v-on:click="toggleLocalList"
            class="local"
            data-custom-toggle=".local-list"
            data-custom-toggle-hide-on-blur="true"
            data-custom-toggle-disable-on-blur="true">
          <div class="local-text">{{ select_lang }}</div>
          <svg class="local-icon"
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L0.535898 0.75L7.4641 0.75L4 6Z" fill="white"></path>
          </svg>
        </div>
        <ul class="local-list">
          <li v-for="(item, index) in list_lang" :key="index" :item="item">
            <a v-on:click="selectLang"
                :data-two_code="item.two_code"
                :data-idx="item.id"
                :data-lng="item.language_name"
                href="javascript:void(0)"
            >{{ item.language_name }}</a>
          </li>
        </ul>
      </div>
      <nav class="nav-menu" v-if="renderMenu">
        <div class="nav-menu-content">
          <ul class="nav-list">
            <li class="nav-item">
              <router-link class="nav-link" to="/" exact>{{$t("home")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about" exact>{{$t("about_us")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pricing" exact>{{$t("pricing")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/services" exact>{{ $t("services") }}</router-link>
            </li>
          </ul>
          <!--
          <div class="nav-btn-wrapper">
            <div>
              <b-button class="outlined-btn no-margin-top w-inline-block" @click="loginFormOpen">{{ $t("to_come_in") }}</b-button>
              <b-modal id="modal-sign-in" hide-header hide-footer centered no-stacking>
                <button class="close-modal" @click="$bvModal.hide('modal-sign-in');closeSignModal()">×</button>
                <h1 class="utility-card-title">{{ $t("login_system") }}</h1>
                <div class="w-form">
                  <form data-name="sign in form">
                    <div v-show="forms.enter_email" class="text-field-wrap small-margin-bottom">
                      <label for="login-email" class="field-label">{{ $t("email") }}</label>
                      <input type="email" class="text-field w-input" :placeholder="$t('email')" required v-model="loginForm.username"/>
                    </div>
                    <input v-show="forms.enter_email" type="button" :value="$t('to_come_in')" data-wait="Please wait..." class="filled-btn full-width w-button" @click="eventChangeUsername"/>
                    <div class="text-field-wrap" v-show="forms.our_user">
                      <label for="Login-Password" class="field-label">{{ $t("password") }}</label>
                      <input type="password" class="text-field w-input" name="login-password" :placeholder="$t('password')" id="Login-Password" required v-model="loginForm.password"/>
                    </div>
                    <input v-show="forms.our_user" type="button" :value="$t('to_come_in')" data-wait="Please wait..." class="filled-btn full-width w-button" @click="eventChangePassword"/>
                    <div class="extra-form-actions-wrapper margin-top center no-margin-bottom" v-show=false>
                      <div class="form-help-text">{{ $t("dont_have_an_account_yet") }}
                        <a href="#" class="text-link" @click="$bvModal.hide('modal-sign-in')" v-b-modal.modal-sign-up>{{ $t("sign_up_here") }}</a>
                      </div>
                    </div>
                    <div v-show="forms.new_user" class="text-field-wrap">
                      <label for="Login-Password" class="field-label">{{ $t("password_new") }}</label>
                      <input type="password" class="text-field w-input" name="login-password" :placeholder="$t('password')" required v-model="loginForm.password"/>
                    </div>
                    <div v-show="forms.new_user" class="extra-form-actions-wrapper">
                      <label class="w-checkbox checkbox-wrap">
                        <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" v-model="loginForm.i_agree_with_the" required=""/>
                        <span class="w-form-label">{{$t("i_agree_with_the")}}<router-link class="terms-agree-link" to="/terms_of_use" target="_blank">{{ $t("terms_of_use")}}</router-link></span>
                      </label>
                    </div>
                    <input v-show="forms.new_user" type="button" :value="$t('to_come_in')" data-wait="Please wait..." class="filled-btn full-width w-button" @click="eventChangeNewPassword"/>
                    <div class="extra-form-actions-wrapper" v-show="forms.our_user">
                      <a href="#" class="text-link" @click="$bvModal.hide('modal-sign-in',)" v-b-modal.modal-forgot-password>{{$t("forgot_your_password")}}</a>
                    </div>
                    <div v-show="forms.new_user" class="extra-form-actions-wrapper margin-top center no-margin-bottom">
                      <div class="form-help-text">{{ $t("already_have_an_account") }}
                        <a href="#" class="text-link" @click="showSignIn" v-b-modal.modal-sign-in>{{ $t("to_come_in") }}</a>
                      </div>
                    </div>
                  </form>
                  <div v-show="forms.done" class="success-message soft"><div>{{forms.messageDone}}</div></div>
                  <div v-show="forms.fail" class="error-message soft"><div>{{forms.messageFail}}</div></div>
                </div>
              </b-modal>
              <b-modal
                  id="modal-forgot-password"
                  hide-header
                  hide-footer
                  centered
                  no-stacking
              >
                <button class="close-modal" @click="$bvModal.hide('modal-forgot-password')">×</button>
                <h1 class="utility-card-title">{{ $t("forgot_your_password") }}</h1>
                <div class="w-form">
                  <form id="wf-form-forgot-password"
                      name="wf-form-sign-in-form"
                      data-name="sign in form">
                    <div class="text-field-wrap small-margin-bottom">
                      <label for="login-email-restore" class="field-label">{{ $t("email") }}</label>
                      <input type="email"
                          class="text-field w-input"
                          name="login-email"
                          :placeholder="$t('email')"
                          id="login-email-restore"
                          v-model="loginForm.username_restore"
                          required=""/>
                    </div>
                    <input
                        v-show="forms.buttonChangeRestorePassword"
                        type="button"
                        :value="$t('send')"
                        data-wait="Please wait..."
                        class="filled-btn full-width w-button"
                        @click="eventChangeRestorePassword"
                    />
                    <div v-show="forms.DoneChangeRestorePassword" class="success-message soft"><div>{{forms.messageDoneChangeRestorePassword}}</div></div>
                    <div v-show="forms.FailChangeRestorePassword" class="error-message soft"><div>{{forms.messageFailChangeRestorePassword}}</div></div>
                  </form>
                </div>
              </b-modal>
            </div>
          </div>
          -->
        </div>
      </nav>
      <button class="nav-menu-toggle" @click="toggleMenu">
        <svg width="26" height="18" viewBox="0 0 26 18" fill="none">
          <path
              d="M1 1H25"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
          <path
              d="M1 9H25"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
          <path
              d="M1 17H25"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
        </svg>
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none">
          <path
              d="M0.930176 1.94958L17.9007 18.9201"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
          <path
              d="M0.930176 18.9202L17.9007 1.9496"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
          />
        </svg>
      </button>
    </div>
  </header>
</template>

<script>
import config from "@/store/config";
import axios from "axios";
import bus from "@/store/bus";
//import {BModal} from 'bootstrap-vue'
//import {i18n} from "@/main";

/*
<script>
  window.fbAsyncInit = function() {
    FB.init({
      appId      : '714285925886496',
      cookie     : true,
      xfbml      : true,
      version    : 'v10.0'
    });

    FB.AppEvents.logPageView();

  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));

   //---
   FB.getLoginStatus(function(response) {
    statusChangeCallback(response);
});

{
    status: 'connected',
    authResponse: {
        accessToken: '...',
        expiresIn:'...',
        signedRequest:'...',
        userID:'...'
    }
}



 */

export default {
  name: "Header",
  data() {
    return {
      list_lang: {},
      select_lang: "",
      select_lang_id: 0,
      renderMenu: true,
      forms : {
        enter_email : true,
        our_user : false,
        new_user : false,
        already_have_an_account : false,
        done : false,
        fail : false,
        messageDone : "",
        messageFail : "",
        buttonChangeRestorePassword : true,
        messageDoneChangeRestorePassword : "",
        messageFailChangeRestorePassword : "",
        DoneChangeRestorePassword : false,
        FailChangeRestorePassword : false
      },
      loginForm: {
        username: '',
        password: '',
        username_restore : '',
        i_agree_with_the : false
      }
    };
  },
  mounted() {
    this.getListLanguage();
    this.changeLocale(this.$cookie.get("elza_lng") != undefined ? this.$cookie.get("elza_lng") : "en");
  },
  methods: {
    loginFormOpen: function () {
      let token = localStorage.getItem("_ElzaCpanelUserToken");
      if (token) {
        this.checkUser(token);
      } else {
        this.$bvModal.show('modal-sign-in');
      }
    },
    checkUser: function (token) {
      axios({
        method: "POST",
        url: `${config.getters.mainUrl}user/checkUser`,
        data: {},
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          'token-auth' : token
        },
      }).then(
          res => {
            if (!res.data.result) {
              this.$bvModal.show('modal-sign-in');
            } else {
              console.log("adasd");
              //window.location.href = "http://localhost:8081" + `?token=${token}`;
              //window.location.href = `${config.getters.adminUrl}?token=${token}`;
            }
          },
          error => {
            console.log(error);
          },
      );
    },
    signUp: function (e) {
      e.preventDefault();
      axios({
        method: "POST",
        url: `${config.getters.mainUrl}auth/signUp`,
        data: Object.assign({}, this.loginForm, {lang  : this.$cookie.get("elza_lng") != undefined ? this.$cookie.get("elza_lng") : "en"}),
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            if (result.data.result) {
              if (result.data.result == true) {

              } else {
                this.forms.fail = true;
              }
            } else {
              this.forms.messageDone = "Kuku";
              this.forms.done = true;
            }
          },
          error => {
            console.log(error);
          },
      );
    },
    toggleMenu: function () {
      if ($(".nav-menu").hasClass("active")) {
        $(".nav-menu").removeClass("active");
      } else {
        $(".nav-menu").addClass("active");
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale; //this.$i18n.locale === 'en' ? 'cn' : 'en'
      //this.$parent.select_lang = locale;
    },
    getListLanguage: function () {
      axios({
        method: "POST",
        url: config.getters.mainUrl + "service/listLanguage?ts=" + Math.round(+new Date() / 1000),
        data: {},
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            if (
                result != undefined &&
                result.data != undefined &&
                result.data.result != undefined
            ) {
              if (result.data.result == true) {
                this.select_lang = result.data.data[0].language_name;
                for (let ii in result.data.data) {
                  if ( this.$cookie.get("elza_lng") == result.data.data[ii].two_code) {
                    this.select_lang = result.data.data[ii].language_name;
                  }
                }
                this.list_lang = result.data.data;
              }
            }
          },
          error => {
            console.log(error);
          },
      );
    },
    toggleLocalList: function () {
      $(".local-list").toggle();
    },
    selectLang: function (e) {
      this.select_lang_id = $(e.target).attr("data-idx");
      this.select_lang = $(e.target).attr("data-lng");
      this.$cookie.set("elza_lng", $(e.target).attr("data-two_code"), 1);
      $(".local-list").hide();
      this.$store.commit("lang", $(e.target).attr("data-two_code"));
      this.setLanguage($(e.target).attr("data-two_code"));

      bus.$emit("re_render", 1);
    },
    setLanguage: function (lang) {
      this.$i18n.locale = lang;

      if (this.$i18n.messages[lang] == undefined) {
        axios({
          method: "POST",
          url: `${config.getters.mainUrl}service/translate`,
          data: {
            type: "interface",
            lng: lang
          },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }).then(
            result => {
              if (
                  result != undefined &&
                  result.data != undefined &&
                  result.data.status != undefined
              ) {
                if (result.data.status == "true") {
                  let self = this;
                  this.renderMenu = false;
                  this.$i18n.setLocaleMessage(
                      lang,
                      result.data.data[lang],
                  );
                  //let timerId = setTimeout(function tick() {
                  this.$i18n.locale = lang;
                  this.$nextTick(() => {
                    self.renderMenu = true;
                  });
                  //}, 500);
                }
              }
            },
            error => {
              console.log(error);
            },
        );
      }
    },
    validateEmail : function (mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },
    eventChangeUsername : function () {
      if (this.validateEmail(this.loginForm.username)) {
        axios({
          method: "POST",
          url: `${config.getters.mainUrl}auth/checkEmail`,
          data: {
            email : this.loginForm.username,
            lang  : this.$cookie.get("elza_lng") != undefined ? this.$cookie.get("elza_lng") : "en"
          },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }).then(
            res => {
              console.log(res);
              if (res.data.result) {
                this.forms.our_user = true;
                this.forms.enter_email = false;
              } else {
                this.forms.new_user = true;
                this.forms.already_have_an_account = true;
                this.forms.enter_email = false;
              }
            },
            error => {
              console.log(error);
            },
        ).catch(err => {
          console.log("catch", err)
        })
        //console.log(this.loginForm.username, this.$cookie.get("elza_lng") || "en");
        //this.form_password_enter = true;
        //this.form_restore_password = true;
        //this.form_username = false;
      } else {
        this.forms.messageFail = this.$t("error_not_valid_email");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
      }
    },
    eventChangePassword : function () {
      axios({
        method: "POST",
        url: `${config.getters.mainUrl}auth/signIn`,
        data: {
          username : this.loginForm.username,
          password : this.loginForm.password
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            console.log(result.data);
            if (result.data.result) {
              if (this.loginForm.remember) {
                localStorage.setItem("_ElzaCpanelUserToken", result.data.data.token);
              }
              window.location.href = config.getters.adminUrl + "?token=" +  result.data.data;//`${config.getters.adminUrl}?token=${result.data.data}`;
            }
          },
          error => {
            console.log(error);
          },
      );
    },
    eventChangeNewPassword: function () {
      if (this.loginForm.i_agree_with_the == true) {
        axios({
          method: "POST",
          url: `${config.getters.mainUrl}auth/signUp`,
          data: Object.assign({}, this.loginForm, {lang  : this.$cookie.get("elza_lng") != undefined ? this.$cookie.get("elza_lng") : "en"}),
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }).then(
            result => {
              if (result.data.result) {
                if (result.data.result == true) {
                  this.forms.messageDone = "Kuku";
                  this.forms.done = true;
                  setTimeout(this.closeDoneMessage, 2000);
                } else {
                  this.forms.fail = true;
                }
              }
            },
            error => {
              console.log(error);
            },
        );
      } else {
        this.forms.messageFail = this.$t("error_validation_you_must_confirm_terms_use");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
      }
    },
    eventChangeRestorePassword: function (char) {
      this.forms.buttonChangeRestorePassword = true;
      axios({
        method: "POST",
        url: `${config.getters.mainUrl}auth/restorePassword`,
        data: Object.assign({}, this.loginForm, {lang  : this.$cookie.get("elza_lng") != undefined ? this.$cookie.get("elza_lng") : "en"}),
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            if (result.data.result) {
              if (result.data.result == true) {
                this.forms.messageDoneChangeRestorePassword = "Ok";
                this.forms.DoneChangeRestorePassword = true;
                setTimeout(this.closeDoneMessageChangeRestorePassword, 5000);
                this.forms.buttonChangeRestorePassword = false;
              } else {
                this.forms.FailChangeRestorePassword = true;
                this.forms.messageFailChangeRestorePassword = "Faild";
                setTimeout(this.closeFailMessageChangeRestorePassword, 5000);
              }
            }
          },
          error => {
            console.log(error);
          },
      );
      console.log(this.loginForm.username_restore);
    },
    showSignIn : function () {
      this.forms.enter_email = true;
      this.forms.new_user = false;
      this.forms.our_user = false;
    },
    closeSignModal : function () {
      this.forms.enter_email = true;
      this.forms.new_user = false;
      this.forms.our_user = false;
    },
    closeFailMessage : function () {
      this.forms.messageFail = "";
      this.forms.fail = false;
    },
    closeDoneMessage : function () {
      this.forms.messageDone = "";
      this.forms.done = false;
    },
    closeDoneMessageChangeRestorePassword : function () {
      this.forms.messageDoneChangeRestorePassword = "";
      this.forms.DoneChangeRestorePassword = false;
    },
    closeFailMessageChangeRestorePassword : function () {
      this.forms.messageFailChangeRestorePassword = "";
      this.forms.FailChangeRestorePassword = false;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/base/variables";

.header {
  position: relative !important;
}

body[style="padding-right: 17px;"] {
  .header {
    padding-right: 17px;
  }
}

.brand-wrapper img {
  float: left;
  height: 40px;
}

.brand-wrapper h5 {
  float: left;
  margin: 0px;
  line-height: 40px;
  margin-left: 10px;
  color: $primary;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.header-sticky {
    background-color: $gray-900;
  }
}

.navbar {
  display: flex;
  align-items: center;
  height: 70px;
}

.nav-list {
  display: flex;
  flex-direction: column;
}

.nav-item {
}

.nav-link {
  display: block;
  margin-right: 8px;
  padding: 8px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: 0.2s all linear;
    transform: translate(-50%, 0);
  }

  &.router-link-active {
    &:before {
      width: 100%;
    }
  }

  &.active,
  &:hover {
    opacity: 0.5;
  }

  .icon-svg {
    margin-left: 5px;
    transition: $transition-base;
    pointer-events: none;
  }
}

.brand {
  display: block;
  height: 24px;
}

.nav-menu.active {
  right: 5px;
}

.nav-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: auto;

  svg {
    transition: $transition-base;
    position: absolute;

    &:first-child {
      opacity: 1;
      transform: scale(1);
    }

    &:last-child {
      opacity: 0;
      transform: scale(0);
    }
  }
}

@media (max-width: 340px) {
  .navbar.container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.nav-menu {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 320px;
  background-color: $gray-700;
  z-index: -1;
  transform: translateX(100%);
  transition: $transition-base;

  &.active {
    transform: translateX(0);
  }

  &.active {
    + .nav-menu-toggle {
      svg {
        &:first-child {
          opacity: 0;
          transform: scale(0);
        }

        &:last-child {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.nav-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  padding: 60px 15px 30px;
}

.nav-btn-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

// Dropdown
.dropdown {
  position: relative;
}

.show {
  .nav-link {
    opacity: 0.5;

    .icon-svg {
      transform: rotate(180deg);
    }
  }
}

.nav-dropdown {
  display: none;
}

.nav-dropdown-list {
  min-width: 150px;
  padding: 10px 20px;
  background-color: $gray-800;
  border-radius: 8px;
}

.nav-dropdown-link {
  display: block;
  padding: 5px 0;
}

@media (min-width: 1200px) {
  .nav-list {
    align-items: center;
    flex-direction: row;
  }

  .nav-menu {
    align-items: center;
    position: relative;
    background-color: transparent;
    width: auto;
    z-index: 1;
    transform: translate(0);
    margin-left: auto;
  }

  .nav-menu-content {
    align-items: center;
    flex-direction: row;
    padding: 0;
    overflow-y: initial;
  }

  .nav-btn-wrapper {
    margin-left: 16px;
    margin-top: 0;
  }

  .nav-menu-toggle {
    display: none;
  }

  // Dropdown
  .nav-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
  }

  .nav-dropdown-list {
    background-color: $gray-700;
    box-shadow: 0 16px 30px 0 rgba(13, 15, 25, 0.5);
  }
}
</style>
