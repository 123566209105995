<template>
</template>

<script>

export default {
    name: "LiveChat",
    data() {
        return {
            showCookie: true,
        };
    },
      mounted() {
          if (this.$cookie.get("elza_cookie") == "true") {
              this.showCookie = false;
          } else {
              this.showCookie = true;
          }

          this.chatInit();
      },

    methods: {
        chatInit:function () {
            let widget_id = 'rXQed8F3JtjnsYPD';
            let _lang = 'ua';
            (function() {
              window.widget_id = 'rXQed8F3JtjnsYPD';
              window.lang = 'ua';

              const d = document;
              const w = window;
              let needJquery = false;
              let scriptJQuery = null;

                /*
                if(typeof jQuery !== 'function') {
                    needJquery = true;
                    let head = document.getElementsByTagName('head')[0];
                    scriptJQuery = document.createElement('script');
                    scriptJQuery.type = 'text/javascript';
                    scriptJQuery.async = false;
                    scriptJQuery.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
                    head.appendChild(scriptJQuery);
                }
               */

                function start() {
                    const container = document.getElementsByTagName('body')[0];
                    let script = document.createElement("script");
                    script.type = 'text/javascript';
                    //script.src = 'https://elzabot.com/static/js/widget.js';
                    script.src = '/widget/app.js';
                    script.async = false;
                    container.appendChild(script);
                }

                function initWidget() {
                    if (d.readyState === 'complete') {
                        start();
                    } else {
                        if (w.attachEvent) {
                            w.attachEvent('onload');
                        } else {
                            w.addEventListener('load', start, false);
                        }
                    }
                }

                //if(needJquery && scriptJQuery) {
                //    scriptJQuery.onload = function () {
                //        initWidget()
                //    }
                //} else {
                    initWidget()
                //}
            })()
        },
        clickCookie: function() {
            this.$cookie.set("elza_cookie", true, { expires: "1M" });
            this.showCookie = false;
        },
        toggleDropdownMenu: function(e) {
            $(e.target)
                .parents(".iconbox-dropdown")
                .find(".dropdown-menu")
                .toggle();
        },
        closeChat: function() {
            // $("#live_chat").hide();
            // $("#live_chat")
            //     .find(".dropdown-menu")
            //     .hide();
            $("#root").show();
        },

        openChat: function() {
            //$("#live_chat").find(".dropdown-menu").close();
            $("#elza_chat").toggle();
            // $("#root").hide();
        },
    },
};
</script>

<style>
</style>
