<template>
  <div class="home">
    <Hero/>
    <!--<Partners/>-->
    <Features/>
  </div>
</template>

<script>
import Hero from '@/sections/Hero.vue'
import Features from '@/sections/Features'
//import Partners from '@/sections/Partners'

export default {
    name:       'Home',
    components: {
      Hero,
      Features,
      //Partners
    },
    mounted() {
    }
}
</script>

<style>

</style>
