<template>
  <div>
    <b-button id="root_support" v-b-modal.modal-support><span><i class="mdi mdi-message-text-outline"></i></span></b-button>
    <b-modal id="modal-support" hide-header hide-footer centered no-stacking>
      <button class="close-modal" @click="$bvModal.hide('modal-support')">×</button>
      <h1 class="utility-card-title">{{ $t("tech_support") }}</h1>
      <form id="wf-form" name="wf-form">
        <div class="text-field-flex-wrap">
          <div class="text-field-wrap small-margin-bottom">
            <label for="First_Name_support" class="field-label">{{$t("first_name")}}</label>
            <input type="text" class="text-field w-input" maxlength="256" name="Sign-In-First-Name"
                   data-name="Sign In First Name" :placeholder="$t('first_name')" id="First_Name_support" required=""
                   v-model="forms.first_name"/>
          </div>
          <div class="text-field-spacer"></div>
          <div class="text-field-wrap small-margin-bottom">
            <label for="Last_Name_support" class="field-label">{{$t("last_name")}}</label>
            <input type="text" class="text-field w-input" maxlength="256" name="Sign-In-Last-Name"
                   data-name="Sign In Last Name" :placeholder="$t('last_name')" required=""
                   v-model="forms.last_name"/>
          </div>
        </div>
        <div class="text-field-wrap">
          <label for="email_support" class="field-label">Email</label>
          <input type="email" class="text-field w-input" maxlength="256" name="email" data-name="Email" placeholder="Email"
                 v-model="forms.email" required=""/></div>
        <div class="text-field-wrap">
          <label for="title_support" class="field-label">{{$t("title")}}</label>
          <input
              type="text"
              class="text-field w-input"
              maxlength="256"
              name="title"
              data-name="Title"
              placeholder="title"
              required=""
              v-model="forms.title"
          />
        </div>
        <div class="text-field-wrap">
          <label for="Subject_support" class="field-label">{{$t("subject")}}</label>
          <select id="Subject_support" name="Subject" data-name="Subject" required="" class="text-field select w-select">
            <option v-for="(option, index) in list_department" :key="index" v-bind:value="index">{{ option.title }}</option>
          </select>
        </div>
        <div class="text-field-wrap large-margin-bottom">
          <label for="field" class="field-label">{{$t("message")}}</label>
          <textarea
              :placeholder="$t('how_can_we_help_you_today')"
              maxlength="5000"
              id="field"
              name="field"
              data-name="Field"
              class="text-field min-height w-input"
              v-model="forms.message"
          ></textarea>
        </div>
        <input
            type="button"
            :value="$t('submit')"
            data-wait="Please wait..."
            v-on:click="sendSupport"
            class="filled-btn full-width w-button"
        />
      </form>
      <div v-show="forms.done" class="success-message soft"><div>{{forms.messageDone}}</div></div>
      <div v-show="forms.fail" class="error-message soft"><div>{{forms.messageFail}}</div></div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/store/config";

export default {
  name: "Cookie",
  data() {
    return {
      list_department: [],
      forms : {
        email : "",
        first_name : "",
        last_name : "",
        title : "",
        message : "",
        subject : "",
        done : false,
        fail : false,
        messageDone : "",
        messageFail : ""
      }
    };
  },
  watch: {
    "$store.state.lang": function () {
      this.getListDepartmen();
    },
  },
  mounted() {
    this.getListDepartmen();
  },
  methods: {
    getListDepartmen: function () {
      axios({
        method: "POST",
        url: config.getters.mainUrl + "service/listDepartment?ts=" + Math.round(+new Date() / 1000),
        data: {
          lang: this.$cookie.get("elza_lng"),
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            if (
                result != undefined &&
                result.data != undefined &&
                result.data.result != undefined
            ) {
              if (result.data.result == true) {
                this.list_department = result.data.data;
              }
            }
          },
          error => {
            console.log(error);
          },
      );
    },
    validationForm: function () {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.forms.email)) {
        this.forms.messageFail = this.$t("error_not_valid_email");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^[a-zA-Z]+$/.test(this.forms.first_name)) {
        this.forms.messageFail = this.$t("error_not_valid_first_name");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^[a-zA-Z]+$/.test(this.forms.last_name)) {
        this.forms.messageFail = this.$t("error_not_valid_last_name");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$/.test(this.forms.title)) {
        this.forms.messageFail = this.$t("error_not_valid_title");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^[a-zA-Z0-9- .#@&,_'":.?!]+/.test(this.forms.message)) {
        this.forms.messageFail = this.$t("error_not_valid_message");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      }
      return true;
    },
    sendSupport: function () {
      if (this.validationForm()) {
        axios({
          method: "POST",
          url: config.getters.mainUrl + "service/createSupport?ts=" + Math.round(+new Date() / 1000),
          data: {
            title: this.forms.title,
            email: this.forms.email,
            department: $("#Subject_support").val(),
            description: this.forms.message,
            first_name: this.forms.first_name,
            last_name: this.forms.last_name,
            lang: this.$cookie.get("elza_lng"),
          },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }).then(
            result => {
              if (
                  result != undefined &&
                  result.data != undefined &&
                  result.data.result != undefined
              ) {
                if (result.data.result == true) {
                  $("#modal-support .close-modal").click();
                }
              }
            },
            error => {
              console.log(error);
            },
        );
      }
    },
    closeFailMessage : function () {
      this.forms.messageFail = "";
      this.forms.fail = false;
    },
    closeDoneMessage : function () {
      this.forms.messageDone = "";
      this.forms.done = false;
    },
  },
};
</script>

<style>
#root_support {
  width: 30px;
  height: 50px;
  position: fixed;
  bottom: 50vh;
  left: 0;
  border-radius: 0 8px 8px 0;
  border-top: 1px solid #eb7305;
  border-bottom: 1px solid #eb7305;
  border-right: 1px solid #eb7305;
  line-height: 58px;
  padding-left: 3px;
  color: #eb7305;
  transition: 0.2s all linear;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

#root_support i {
  color: #eb7305;
  position: relative;
  top: 3px;
}

#root_support:hover {
  color: white;
  background-color: #eb7305;
}

#root_support:hover i {
  color: white;
}
</style>
