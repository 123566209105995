<template>
    <div class="cookie-bar" v-show="showCookie">
        <div class="cookie-text">{{$t("we_use_cookies_to_give")}}
            <router-link target="_blank" class="cookie-link" to="cookies">Cookies</router-link>
        </div>
        <button v-on:click="clickCookie" class="filled-btn no-margin-top no-margin-right close-cookie-bar w-button">{{$t("ok")}}</button>
    </div>
</template>

<script>
export default {
    name: "Cookie",
    data() {
        return {
            showCookie: true,
        }
    },
    mounted() {
      if (this.$cookie.get("elza_cookie") == "true") {
          this.showCookie = false;
      } else {
          this.showCookie = true;
      }
    },
    methods : {
        clickCookie : function () {
            this.$cookie.set("elza_cookie", true, {expires: "1M"});
            this.showCookie = false
        }
    }
}
</script>

<style lang=scss>
@import '../assets/scss/base/variables';

.cookie-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  max-width: 640px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  padding: 32px;
  align-items: center;
  border-radius: 8px;
  background-color: $gray-800;
  box-shadow: 0 16px 30px 0 rgba(13, 15, 25, 0.5);
  font-weight: 500;
  white-space: normal;
}

.cookie-link {
  text-decoration: underline;
}

.cookie-text {
  margin-right: 16px;
}

@media screen and (max-width: 479px) {
    .cookie-bar {
        flex-direction: column;
    }

    .cookie-text {
        margin-bottom: 20px;
        text-align: center;
    }

  .filled-btn.no-margin-top.no-margin-right.close-cookie-bar {
    width: 100%;
  }
}
</style>
