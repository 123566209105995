<template>
    <section class="hero-section">
        <div class="container hero-flex-horizontal">
            <div class="hero-left">
                <h1
                    class="hero-title wow fadeInLeft"
                    data-wow-delay=".1s"
                    v-html="$t('slogan_title')"
                ></h1>
                <ListMarker class="wow fadeInLeft" data-wow-delay=".2s">
                    <li>
                      <p>{{$t("elzabot_is_set_of_tools")}}</p>
                    </li>
                  <li>
                      <p>{{$t("elzabot_can_be_used_both_in_private")}}</p>
                  </li>
                  <li>
                    <p>{{$t("elzabot_can_be_configured_to_work_with_various_api")}}</p>
                  </li>
                    <li>
                      <p>{{$t("in_addition_elzabot_can_be_expanded_and_customized")}}</p>
                    </li>
                  <!--
                        <p>{{ $t("direct_forwarding_of_messages") }}</p>
                    </li>
                    <li>
                        <p>{{ $t("collecting_news_in_one_channel") }}</p>
                    </li>
                    <li>
                        <p>
                            {{
                                $t(
                                    "message_forwarding_to_the_right_recipient_telegram",
                                )
                            }}
                        </p>
                    </li>
                    -->
                </ListMarker>
                <div
                    class="hero-btn-container wow fadeInLeft"
                    data-wow-delay=".3s"
                >
                    <a
                        href="#services"
                        class="filled-btn no-margin-top w-inline-block"
                    >
                        <div>{{ $t("explore_features") }}</div>
                    </a>
                    <a
                        data-light-gallery="vide"
                        href="https://www.youtube.com/watch?v=jir0W4W9p7w"
                        class="text-btn no-margin-top w-inline-block w-lightbox"
                    >
                        <img
                            src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f0878c5cdda7be0958d764c_Video%20Icon.svg"
                            alt=""
                            class="text-btn-icon"
                        />
                        <div>{{ $t("see_how_it_works") }}</div>
                    </a>
                </div>
            </div>
            <div class="hero-right wow fadeInRight">
                <img
                    src="../assets/img/bg-signup.webp"
                    alt=""
                    class="hero-img"
                />
            </div>
        </div>
    </section>
</template>

<script>
import ListMarker from "@/components/ListMarker";

export default {
    name: "Hero",
    components: {
        ListMarker,
    },
};
</script>

<style lang="scss">
@import "../assets/scss/base/variables";

.our_clients {
    width: 400px;
    margin: 0px auto;
}

.our_clients img {
    width: 100px;
    height: 100px;
    margin-right: 50px;
}

.container.hero-flex-horizontal {
    display: flex;
    align-items: center;
}

.container.hero-flex-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section {
    /*padding-top: 180px;*/
    padding-top: 50px;
    padding-bottom: 90px;
    flex-direction: row;
    align-items: center;
}

.hero-section.grey {
    background-color: $gray-800;
}

.hero-section.grey.relative {
    position: relative;
}

.hero-left {
    z-index: 2;
    width: 50%;
    padding-right: 8.333%;
    flex-direction: column;
}

.hero-right {
    position: relative;
    z-index: 1;
    width: 50%;
}

.hero-title {
    max-width: 100%;
    margin-top: 0;
}

.hero-btn-container {
    display: flex;
    margin-top: 40px;
    align-items: center;
}

.hero-btn-container.center {
    margin-top: 48px;
    justify-content: center;
}

.hero-top {
    z-index: 2;
    width: 50%;
    margin-bottom: 80px;
    flex-direction: column;
}

.hero-bottom {
    z-index: 0;
    width: 100%;
}

.hero-email-form {
    margin-top: 48px;
    margin-bottom: 0;
}

.hero-img {
    border-radius: 8px;
}

@media screen and (max-width: 1024px) {
    .hero-title {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .hero-section .list-marker {
        display: table;
        width: 100%;
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
    }

    .hero-btn-container {
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {
    .container.hero-flex-horizontal {
        flex-direction: column;
        align-items: stretch;
    }
    .hero-section {
        padding-top: 160px;
    }
    .hero-left {
        width: 100%;
        margin-bottom: 64px;
        padding-right: 0;
        justify-content: center;
    }
    .hero-right {
        width: 100%;
    }
    .hero-right.first-on-mobile {
        margin-bottom: 48px;
        order: -1;
    }
    .hero-title {
        max-width: 75%;
    }
    .hero-top {
        display: flex;
        width: 100%;
        margin-bottom: 64px;
        padding-right: 0;
        justify-content: center;
        align-items: center;
    }
    .hero-bottom {
        width: 100%;
    }
    .intro-hero-section {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}

@media screen and (max-width: 767px) {
    .hero-section {
        padding-top: 120px;
        padding-bottom: 96px;
        flex-direction: column;
    }
    .hero-title {
        max-width: 100%;
        font-size: 36px;
        line-height: 1.3;
        text-align: center;
    }
    .hero-btn-container {
        margin-top: 40px;
        flex-direction: row;
    }
    .intro-hero-section {
        padding-top: 72px;
        padding-bottom: 72px;
    }
}

@media screen and (max-width: 479px) {
    .container.hero-flex {
        flex: 0 auto;
    }
    .hero-section {
        padding-top: 96px;
        padding-bottom: 72px;
    }
    .hero-left {
        margin-bottom: 40px;
    }
    .hero-btn-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .hero-btn-container.center {
        margin-top: 24px;
        align-items: center;
    }
    .hero-top {
        margin-bottom: 40px;
    }
    .intro-hero-section {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}
</style>
