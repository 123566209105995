<template>
    <div id="app" class="page">
        <Header v-if="show_item" />
        <transition name="slideTop" mode="out-in">
            <router-view></router-view>
        </transition>
        <Footer v-if="show_item" />
        <Cookie />
        <LiveChat />
        <Support />
    </div>
</template>

<script>
import Header from "@/sections/Header";
import Footer from "@/sections/Footer";
import Cookie from "@/components/Cookie";
import LiveChat from "@/components/_LiveChat";
import Support from "@/components/Support";

export default {
    components: {
        Header,
        Footer,
        Cookie,
        LiveChat,
        Support,
    },
    data() {
        return {
            show_item: true,
            transitionName: "out-down",
        };
    },
    updated() {
        //if (this.$route.name.toLowerCase() == "cpanel") {
        //    this.show_item = false;
        //}
        //console.log(this.$route);
        //console.log("asdasd");
    },
    mounted() {
        //console.log(this.$route);
        //console.log("a211");
    },
    watch: {
        //'$route' (to, from) {
        //console.log(to, from);
        //this.transitionName = from.meta.order < to.meta.order ? 'out-up' : 'out-down'
        //}
    },
};
</script>

<style lang="scss">
@import "assets/scss/base/variables";
.to-top {
    background-color: $primary;
    padding: 15px;
    border-radius: 4px;

    &:hover {
        background-color: darken($primary, 5);
    }
}

body {
    overflow: auto !important;
}

body::-webkit-scrollbar {
    display: none;
}
</style>
