import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		notes: []
	},
	actions: {
		addNote({commit}, note) {
			commit('ADD_NOTE', note)
		}
	},
	mutations: {
		ADD_NOTE(state, note) {
			state.notes.push(note)
		}
	},
	getters: {
		notes(state) {
			return state.notes
		},
		adminUrl() {
			if (process.env.NODE_ENV == "production") {
				return "https://app.elzabot.com/";
			} else {
				return "http://localhost:8081/";
			}
			//return "http://appelza.cyberrit.net/"
		},
		rootUrl() {
			if (process.env.NODE_ENV == "production") {
				return "https://elzabot.com/";
			} else {
				return "http://localhost:8080";
			}
			//return "http://appelza.cyberrit.net/"
		},
		mainUrl() {
			if (process.env.NODE_ENV == "production") {
				return "https://elzabot.com/api/v1/";
			} else {
				return "http://localhost:3000/api/v1/";
			}
		},
		wsUrl() {
			return "http://138.68.82.4:1337";
			//if (process.env.NODE_ENV == "production") {
			//return 'https://elzabot.com';
			//} else {
			//    return "http://138.197.47.76:1337";
			//}
		}
	}
})
